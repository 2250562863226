<template>
  <div>
    <header-menu ref="HeaderMenu"></header-menu>
    <div class="view-content">
      <div class="banner">
        <div class="desc__box text_left">
          <h1 class="banner_title b_b_space font-size_60">
            正也S2P费用结算管理<br />解决方案
          </h1>
          <p class="banner_desc b_b_space font-size_20">
            正也S2P智能管理平台针对招商代理营销模式下的管理痛点，采用协议及合同管理、辖区管理、进销存管理、费用管理、回款管理以及预警管理，对营销过程中的执行状态进行统计分析预警。以“（流向+单据）×算法”的策略，围绕流向、费用、回款、结算，对数据进行智能计算分析。
          </p>
        </div>
      </div>
      <div class="l_r_space t__space informatization_1">
        <h2 class="nav__title">渠道与费用管控的现状</h2>
        <div style="padding-top: 0.7rem">
          <a-row :gutter="165" type="flex">
            <a-col
              class="space_p_b-four"
              :xs="24"
              :sm="12"
              :md="12"
              :lg="8"
              :xl="8"
              :xxl="8"
              v-for="(item, index) in statusList"
              :key="index"
            >
              <div
                :style="{
                  background: `url(${item.img_url})`,
                  width: item.width,
                  height: item.height,
                }"
                class="bgz"
              ></div>
              <div>
                <h3 class="col_title1 b_b_space font-size_26">
                  {{ item.title }}
                </h3>
                <p class="col_desc1 text_left font-size_16">{{ item.desc }}</p>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="l_r_space t__space bgc_f4fbff informatization_2">
        <h2 class="nav__title">我们能帮您做什么</h2>
        <div class="tab_informatization" style="padding-top: 0.7rem">
          <a-tabs
            default-active-key="1"
            :tabBarStyle="{
              borderBottom: 'none',
            }"
          >
            <a-tab-pane key="1" tab="采购订单管理">
              <div class="space_p_t-six">
                <a-row :gutter="165" type="flex">
                  <a-col
                    class="space_p_b-four"
                    :xs="24"
                    :sm="12"
                    :md="12"
                    :lg="8"
                    :xl="8"
                    :xxl="8"
                    v-for="(item, index) in helpList"
                    :key="index"
                  >
                    <div
                      :style="{
                        background: `url(${item.img_url})`,
                        width: '76px',
                        height: '76px',
                      }"
                      class="bgz"
                    ></div>
                    <div>
                      <h3 class="col_title2 b_b_space font-size_24">
                        {{ item.title }}
                      </h3>
                      <p
                        v-if="index == 0"
                        class="col_desc1 b_b_space text_left font-size_16"
                        style="padding-left: 0.55rem; padding-right: 0.48rem"
                      >
                        {{ item.desc }}
                      </p>
                      <p
                        v-if="index == 1"
                        class="col_desc1 b_b_space text_left font-size_16"
                        style="padding-left: 0.21rem; padding-right: 0.18rem"
                      >
                        {{ item.desc }}
                      </p>
                      <p
                        v-if="index == 2"
                        class="col_desc1 b_b_space text_left font-size_16"
                      >
                        {{ item.desc }}
                      </p>
                    </div>
                  </a-col>
                </a-row>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="年度协议管理">
              <div class="space_p_t-six">
                <a-row :gutter="80" type="flex">
                  <a-col
                    class="space_p_b-four"
                    :xs="24"
                    :sm="12"
                    :md="12"
                    :lg="12"
                    :xl="12"
                    :xxl="12"
                    v-for="(item, index) in helpList1"
                    :key="index"
                  >
                    <div
                      :style="{
                        background: `url(${item.img_url})`,
                        width: '76px',
                        height: '76px',
                      }"
                      class="bgz"
                    ></div>
                    <div>
                      <h3 class="col_title2 b_b_space font-size_24">
                        {{ item.title }}
                      </h3>
                      <p class="col_desc1 b_b_space text_center font-size_16">
                        {{ item.desc }}
                      </p>
                    </div>
                  </a-col>
                </a-row>
              </div>
            </a-tab-pane>
            <a-tab-pane key="3" tab="费控管理">
              <div class="space_p_t-six">
                <a-row :gutter="80" type="flex">
                  <a-col
                    class="space_p_b-four"
                    :xs="24"
                    :sm="12"
                    :md="12"
                    :lg="8"
                    :xl="8"
                    :xxl="8"
                    v-for="(item, index) in helpList2"
                    :key="index"
                  >
                    <div
                      :style="{
                        background: `url(${item.img_url})`,
                        width: '76px',
                        height: '76px',
                      }"
                      class="bgz"
                    ></div>
                    <div>
                      <h3 class="col_title2 b_b_space font-size_24">
                        {{ item.title }}
                      </h3>
                      <p class="col_desc1 b_b_space text_center font-size_16">
                        {{ item.desc }}
                      </p>
                    </div>
                  </a-col>
                </a-row>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
      <div class="l_r_space t__space informatization_3">
        <h2 class="nav__title">S2P优势</h2>
        <p
          class="nav__desc font-size_24"
          style="padding: 0.15rem 1.61rem 0.9rem 1.61rem"
        >
          S2P综合平台可在招商代理营销模式下，通过流向数据的采集、清洗，对协议以及合同的执行状态进行统计分析，计算待销库存和返利数据
        </p>
        <a-row :gutter="140" type="flex">
          <a-col
            class="space_p_b-four"
            :xs="24"
            :sm="12"
            :md="12"
            :lg="8"
            :xl="8"
            :xxl="8"
            v-for="(item, index) in advantageList"
            :key="index"
          >
            <div
              :style="{
                background: `url(${item.img_url})`,
                width: item.width,
                height: item.height,
              }"
              class="bgz"
            ></div>
            <div>
              <h3 class="col_title3 b_b_space font-size_26">
                {{ item.title }}
              </h3>
              <p
                class="col_desc1 font-size_16"
                style="
                  text-align: center;
                  padding-left: 0.075rem;
                  padding-right: 0.075rem;
                "
              >
                {{ item.desc }}
              </p>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "@/components/Footer";
import HeaderMenu from "@/components/HeaderMenu";
import channel_1 from "@/assets/img/png/channel_1.png";
import channel_2 from "@/assets/img/png/channel_2.png";
import channel_3 from "@/assets/img/png/channel_3.png";
import channel_4 from "@/assets/img/png/channel_4.png";
import channel_5 from "@/assets/img/png/channel_5.png";
import channel_6 from "@/assets/img/png/channel_6.png";
import channel_7 from "@/assets/img/png/channel_7.png";
import informatization_7 from "@/assets/img/png/informatization_7.png";
import informatization_8 from "@/assets/img/png/informatization_8.png";
import informatization_9 from "@/assets/img/png/informatization_9.png";

export default {
  name: "channelOfDistribution",
  components: { Footer, HeaderMenu },
  data() {
    return {
      statusList: [
        {
          title: "经销商与终端机构关联缺失",
          width: "88px",
          height: "85px",
          desc: "对经销商所负责的终端机构数据上缺少绑定关系，会直接导致在返利上的计算并不精确",
          img_url: channel_1,
        },
        {
          title: "不重视终端机构的管理",
          width: "90px",
          height: "84px",
          desc: "缺少对终端机构的管理，对销量的提升更多采用决策者的人为判断",
          img_url: channel_2,
        },
        {
          title: "发货数据与流向数据断层",
          width: "91px",
          height: "87px",
          desc: "目前经销商的发货数据与代理商的流向数量中缺少关联，将会直接影响到生产的预估风险",
          img_url: channel_3,
        },
      ],
      helpList: [
        {
          title: "线上采购",
          desc: "支持多渠道（配送商、代理商、终端客户）线上采购",
          img_url: informatization_7,
        },
        {
          title: "多场景订单",
          desc: "支持多场景（PC/APP/H5）提交采购订单",
          img_url: informatization_8,
        },
        {
          title: "自动生成发货单",
          desc: "基于采购订单自动生成发货单，为费用核算提供依据",
          img_url: informatization_9,
        },
      ],
      helpList1: [
        {
          title: "电子化管理",
          desc: "年度协议电子化管理，支持多种协议类型（连锁、配送商、代理商协议等），更多协议类型由企业可自行配置",
          img_url: informatization_7,
        },
        {
          title: "多样化政策",
          desc: "不同协议均可维护不同产品政策，支持一键年度结转",
          img_url: informatization_8,
        },
      ],
      helpList2: [
        {
          title: "费用预算、借款与预付款管理",
          desc: "企业资金池管理，支持各项费用预算的支出、核销",
          img_url: informatization_7,
        },
        {
          title: "费用报销管理",
          desc: "费用的报销通过线上快速进行申请，相关业务部门对报销的整个流程，如计划、执行、完成的全过程有效管控。快速帮助企业减少不必要的流程",
          img_url: informatization_8,
        },
        {
          title: "费用审批管理",
          desc: "自定义审批流方便企业多费用多层级审批",
          img_url: informatization_9,
        },
      ],
      advantageList: [
        {
          title: "建立药企分销体系下的辖区关系",
          width: "75px",
          height: "81px",
          desc: "对招商经理、产品、规格、价格、经销商、代理商、终端机构建立一整套关联关系",
          img_url: channel_4,
        },
        {
          title: "对药企建立智能AI的库存管理",
          width: "73px",
          height: "73px",
          desc: "先进的数据计算模型为药企可以智能统计出各层级的库存数据，且该数据模型具有自我学习功能",
          img_url: channel_5,
        },
        {
          title: "多维度的预警功能",
          width: "77px",
          height: "77px",
          desc: "可以为药企自定义配置多维度的预警设置，使风险防范于未然",
          img_url: channel_6,
        },
      ],
    };
  },
  methods: {},
};
</script>
<style scoped lang="scss">
@import "@/assets/style/color.scss";
.col_title1 {
  padding: 0.25rem 0 0.12rem 0;
  color: $color-darkBlue;
  text-align: center;
}
.col_desc1 {
  color: $color-fixGray;
  margin-bottom: 0;
}
.col_title2 {
  padding: 0.15rem 0 0.2rem 0;
  font-weight: bold;
}
.col_title3 {
  padding: 0.35rem 0 0.12rem 0;
  color: $color-darkMinBlue;
  font-weight: bold;
}
.banner {
  width: 100%;
  position: relative;
  background: url("~@/assets/img/png/channel_banner.png");
  background-size: 100% 100%;
  height: 8rem;
  overflow: hidden;
  .desc__box {
    position: absolute;
    top: 1.5rem;
    left: 13.5%;
    .banner_title {
      color: $color-lightBlue;
    }
    .banner_desc {
      width: 5.9rem;
      padding-top: 0.45rem;
      margin-bottom: 0;
    }
  }
}
.informatization_1 {
  padding-bottom: 0.1rem;
}
.informatization_2 {
  padding-bottom: 0.7rem;
}
.informatization_3 {
  padding-bottom: 0.7rem;
}
.bgz {
  background-size: 100% 100%;
  margin: 0 auto;
}
.tab_informatization {
  // /deep/ .ant-tabs-bar {
  //   border-bottom: none;
  //   .ant-tabs-nav-scroll {
  //     color: $color-fixGray;
  //     font-size: 0.26rem;
  //     .ant-tabs-nav .ant-tabs-tab-active {
  //       color: $color-darkBlue;
  //       font-weight: bold;
  //     }
  //   }
  // }
}
.space_p_b-four {
  padding-bottom: 0.4rem;
}
.space_p_t-six {
  padding-top: 0.6rem;
}
</style>
