var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header-menu',{ref:"HeaderMenu"}),_c('div',{staticClass:"view-content"},[_vm._m(0),_c('div',{staticClass:"l_r_space t__space informatization_1"},[_c('h2',{staticClass:"nav__title"},[_vm._v("渠道与费用管控的现状")]),_c('div',{staticStyle:{"padding-top":"0.7rem"}},[_c('a-row',{attrs:{"gutter":165,"type":"flex"}},_vm._l((_vm.statusList),function(item,index){return _c('a-col',{key:index,staticClass:"space_p_b-four",attrs:{"xs":24,"sm":12,"md":12,"lg":8,"xl":8,"xxl":8}},[_c('div',{staticClass:"bgz",style:({
                background: ("url(" + (item.img_url) + ")"),
                width: item.width,
                height: item.height,
              })}),_c('div',[_c('h3',{staticClass:"col_title1 b_b_space font-size_26"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('p',{staticClass:"col_desc1 text_left font-size_16"},[_vm._v(_vm._s(item.desc))])])])}),1)],1)]),_c('div',{staticClass:"l_r_space t__space bgc_f4fbff informatization_2"},[_c('h2',{staticClass:"nav__title"},[_vm._v("我们能帮您做什么")]),_c('div',{staticClass:"tab_informatization",staticStyle:{"padding-top":"0.7rem"}},[_c('a-tabs',{attrs:{"default-active-key":"1","tabBarStyle":{
            borderBottom: 'none',
          }}},[_c('a-tab-pane',{key:"1",attrs:{"tab":"采购订单管理"}},[_c('div',{staticClass:"space_p_t-six"},[_c('a-row',{attrs:{"gutter":165,"type":"flex"}},_vm._l((_vm.helpList),function(item,index){return _c('a-col',{key:index,staticClass:"space_p_b-four",attrs:{"xs":24,"sm":12,"md":12,"lg":8,"xl":8,"xxl":8}},[_c('div',{staticClass:"bgz",style:({
                      background: ("url(" + (item.img_url) + ")"),
                      width: '76px',
                      height: '76px',
                    })}),_c('div',[_c('h3',{staticClass:"col_title2 b_b_space font-size_24"},[_vm._v(" "+_vm._s(item.title)+" ")]),(index == 0)?_c('p',{staticClass:"col_desc1 b_b_space text_left font-size_16",staticStyle:{"padding-left":"0.55rem","padding-right":"0.48rem"}},[_vm._v(" "+_vm._s(item.desc)+" ")]):_vm._e(),(index == 1)?_c('p',{staticClass:"col_desc1 b_b_space text_left font-size_16",staticStyle:{"padding-left":"0.21rem","padding-right":"0.18rem"}},[_vm._v(" "+_vm._s(item.desc)+" ")]):_vm._e(),(index == 2)?_c('p',{staticClass:"col_desc1 b_b_space text_left font-size_16"},[_vm._v(" "+_vm._s(item.desc)+" ")]):_vm._e()])])}),1)],1)]),_c('a-tab-pane',{key:"2",attrs:{"tab":"年度协议管理"}},[_c('div',{staticClass:"space_p_t-six"},[_c('a-row',{attrs:{"gutter":80,"type":"flex"}},_vm._l((_vm.helpList1),function(item,index){return _c('a-col',{key:index,staticClass:"space_p_b-four",attrs:{"xs":24,"sm":12,"md":12,"lg":12,"xl":12,"xxl":12}},[_c('div',{staticClass:"bgz",style:({
                      background: ("url(" + (item.img_url) + ")"),
                      width: '76px',
                      height: '76px',
                    })}),_c('div',[_c('h3',{staticClass:"col_title2 b_b_space font-size_24"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('p',{staticClass:"col_desc1 b_b_space text_center font-size_16"},[_vm._v(" "+_vm._s(item.desc)+" ")])])])}),1)],1)]),_c('a-tab-pane',{key:"3",attrs:{"tab":"费控管理"}},[_c('div',{staticClass:"space_p_t-six"},[_c('a-row',{attrs:{"gutter":80,"type":"flex"}},_vm._l((_vm.helpList2),function(item,index){return _c('a-col',{key:index,staticClass:"space_p_b-four",attrs:{"xs":24,"sm":12,"md":12,"lg":8,"xl":8,"xxl":8}},[_c('div',{staticClass:"bgz",style:({
                      background: ("url(" + (item.img_url) + ")"),
                      width: '76px',
                      height: '76px',
                    })}),_c('div',[_c('h3',{staticClass:"col_title2 b_b_space font-size_24"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('p',{staticClass:"col_desc1 b_b_space text_center font-size_16"},[_vm._v(" "+_vm._s(item.desc)+" ")])])])}),1)],1)])],1)],1)]),_c('div',{staticClass:"l_r_space t__space informatization_3"},[_c('h2',{staticClass:"nav__title"},[_vm._v("S2P优势")]),_c('p',{staticClass:"nav__desc font-size_24",staticStyle:{"padding":"0.15rem 1.61rem 0.9rem 1.61rem"}},[_vm._v(" S2P综合平台可在招商代理营销模式下，通过流向数据的采集、清洗，对协议以及合同的执行状态进行统计分析，计算待销库存和返利数据 ")]),_c('a-row',{attrs:{"gutter":140,"type":"flex"}},_vm._l((_vm.advantageList),function(item,index){return _c('a-col',{key:index,staticClass:"space_p_b-four",attrs:{"xs":24,"sm":12,"md":12,"lg":8,"xl":8,"xxl":8}},[_c('div',{staticClass:"bgz",style:({
              background: ("url(" + (item.img_url) + ")"),
              width: item.width,
              height: item.height,
            })}),_c('div',[_c('h3',{staticClass:"col_title3 b_b_space font-size_26"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('p',{staticClass:"col_desc1 font-size_16",staticStyle:{"text-align":"center","padding-left":"0.075rem","padding-right":"0.075rem"}},[_vm._v(" "+_vm._s(item.desc)+" ")])])])}),1)],1)]),_c('Footer')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner"},[_c('div',{staticClass:"desc__box text_left"},[_c('h1',{staticClass:"banner_title b_b_space font-size_60"},[_vm._v(" 正也S2P费用结算管理"),_c('br'),_vm._v("解决方案 ")]),_c('p',{staticClass:"banner_desc b_b_space font-size_20"},[_vm._v(" 正也S2P智能管理平台针对招商代理营销模式下的管理痛点，采用协议及合同管理、辖区管理、进销存管理、费用管理、回款管理以及预警管理，对营销过程中的执行状态进行统计分析预警。以“（流向+单据）×算法”的策略，围绕流向、费用、回款、结算，对数据进行智能计算分析。 ")])])])}]

export { render, staticRenderFns }